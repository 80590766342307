<template>
  <div class="mobile-product-page">
    <div class="mobile-product-page__wrapper">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>产品展示</el-breadcrumb-item>
        <el-breadcrumb-item v-if="activeName">{{ activeName }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="product-list-wrapper">
        <div class="product-type-list">
          <div
            :class="['product-type', activeId === type.id ? 'active' : '']"
            v-for="type of typeList.filter(item => item.parentId === 0)"
            :key="type.id"
            @click="handleClick(type.id, type.typeName)"
          >
            {{type.typeName}}
          </div>
        </div>
        <div class="product-list">
          <template v-if="productList.length > 0">
            <div
              class="product"
              v-for="product of productList"
              :key="product.id"
            >
              <img :src="`${$host}:7001/public/${product.productPic}`" alt="NO IMAGE">
              <div class="product-name">{{ product.productName }}</div>
            </div>
          </template>
          <template v-else>
            <div class="child-type" v-for="child of childList" :key="child.id">
              <div class="child-type__title">
                {{child.typeName}}
              </div>
              <div class="children-list">
                <div
                  class="product"
                  v-for="product of child.children"
                  :key="product.id"
                >
                  <img :src="`${$host}:7001/public/${product.productPic}`" alt="NO IMAGE">
                  <div class="product-name">{{ product.productName }}</div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";

  export default {
    data() {
      return {
        activeId: 0,
        activeName: '',
        typeList: [],
        allList: [],
      }
    },
    computed: {
      productList() {
        const { activeId, allList, typeList } = this
        return allList.filter((item) => item.typeId === activeId)
      },
      childList() {
        const { activeId, allList, typeList } = this
        const childList = []
        for (let i = 0; i < typeList.length; i++) {
          if (typeList[i].parentId === activeId) {
            const temp = {...typeList[i]}
            temp.children = allList.filter(obj => obj.typeId === typeList[i].id)
            childList.push(temp)
          }
        }
        return childList
      }
    },
  
    mounted() {
      this.getProductList()
      this.getTypeList()
    
    },
    methods: {
      getChildList(id) {
        const { typeList } = this
        return typeList.filter(item => item.parentId === id)
      },
      handleClick(activeId, activeName) {
        this.activeId = activeId
        this.activeName = activeName
      },
      getTypeList() {
        axios.get('/api/type').then(data => {
          this.typeList = data.data
          this.activeId = this.typeList[0].id
          this.activeName = this.typeList[0].typeName
        })
      },
      getProductList() {
        axios.get('/api/product').then(data => {
          this.allList = data.data
        })
      }
    }
  }
</script>

<style lang="less">
  .mobile-product-page {
    &__wrapper {
      padding: 8px;
      
      .product-list-wrapper {
        margin-top: 8px;
        
        .product-type-list {
          display: flex;
          flex-wrap: wrap;
          .product-type {
            width: calc((100% - 8px) / 2);
            background-color: #d2d3d4;
            color: #333333;
            margin-bottom: 4px;
            text-align: center;
            padding: 8px 0;
            
            &:nth-child(2n) {
              margin-left: 8px;
            }
            
            &.active {
              background-color: #009ac4;
              color: #ffffff;
            }
          }
          
          
        }
        
        .product-list {
          margin-top: 16px;
          display: flex;
          flex-wrap: wrap;
          .product {
            border: 1px solid #d8d8d8;
            display: inline-block;
            text-align: center;
            cursor: pointer;
            margin-bottom: 4px;
            width: calc((100% - 12px) / 2);
            
            &:nth-child(2n) {
              margin-left: 8px;
            }
    
            &:hover {
              box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
            }
    
            img {
              margin-bottom: 16px;
              width: 100%;
            }
    
            .product-name {
              background-color: #2256a8;
              color: #ffffff;
              padding: 14px;
              white-space: pre-wrap;
            }
          }
          
          .child-type {
            width: 100%;
            &__title {
              width: 100%;
              background-color: #d2d3d4;
              color: #333333;
              margin-bottom: 4px;
              padding: 6px 8px;
            }
          }
        }
      }
    }
  }
</style>
